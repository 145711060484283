/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect, useState } from "react";
import HintIcon from "components/HintIcon";
import axios from "axios";
import { useTime } from "hooks/timeHook";
//MUI
import { withStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import { pink } from "@material-ui/core/colors";
import Typography from "@material-ui/core/Typography";

//icons
import Message from "@material-ui/icons/Message";

const styles = (theme) => {
  let fullHeight = document.documentElement.clientHeight;
  let height = fullHeight * 0.45 + "px";
  return {
    orderWrap: {
      position: "relative",
      textAlign: "start",
      margin: "1%",
      width: "23%",
      height,
      padding: "10px",
      cursor: "pointer",
      transition: "0.3s",
      background: "white",
    },
    createdAt: {
      fontSize: "1.2rem",
      position: "absolute",
      color: theme.palette.secondary.main,
      bottom: 1,
      right: 5,
    },
    sizeWrap: {
      width: "60%",
      display: "flex",
      justifyContent: "space-between",
    },
    orderTitle: {
      lineHeight: 1,
    },
    sauce: {
      width: "100%",
      height: "20px",
      paddingBottom: "5px",
      borderRadius: "20px",
    },
    orderDisabled: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      background: "rgba(0, 0, 0, 0.1)",
    },
    comment: {
      color: "red",
    },
    notPizza: {
      width: "100%",
      height: "140px",
    },
    audio: {
      display: "none",
    },
    commentButton: {
      position: "absolute",
      zIndex: 999,
      top: 0,
      right: 0,
    },
  };
};

const Order = (props) => {
  const commentEl = useRef(null);
  const { data, classes } = props;
  const { dateFromNow } = useTime();
  const [formatedDate, setFormatedDate] = useState(
    `${dateFromNow(data.created_at)}`
  );
  let orderStatus = "";
  const sauceOption =
    data.product.options.length > 1 ? data.product.options[1].value : null;
  const sauceColor =
    sauceOption === "Ранч"
      ? { background: "#dbd1eb" }
      : sauceOption === "Сырный"
      ? { background: "#FAE160" }
      : sauceOption === "Барбекю"
      ? { background: "#681313" }
      : sauceOption === "Кисло-сладкий"
      ? { background: "#da7a2f" }
      : { background: "transparent", border: "2px solid black" };

  useEffect(() => {
    setFormatedDate(`${dateFromNow(data.created_at)}`);
    let timer = null;
    timer = setInterval(() => {
      setFormatedDate(`${dateFromNow(data.created_at)}`);
    }, 30000);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const sendDataObj = {
    order_product_id: data.product.id,
    order_id: data.order_id,
  };

  const sendData = async () => {
    await axios({
      method: "post",
      url: `/kitchen/station/${props.stage}`,
      params: sendDataObj,
    });
  };

  const makeOrderActive = (e) => {
    if (e.target.classList.contains("disabled")) {
      return;
    }
    if (
      e.target.tagName === "path" ||
      e.target.tagName === "BUTTON" ||
      e.target.tagName === "svg"
    ) {
      return;
    }
    if (
      !data.is_active &&
      e.currentTarget.style.backgroundColor !== "rgb(223, 255, 208)"
    ) {
      sendData();
    }
  };

  const orderComment = data.order && data.order.comment ? (
    <HintIcon
      config={{
        ref: commentEl,
        class: classes.commentButton,
        placement: "bottom",
        text: data.order.comment,
        opened: false,
      }}
    >
      <Message style={{ color: pink[700] }} />
    </HintIcon>
  ) : null;
  switch (props.stage) {
    case "one":
      orderStatus =
        data.is_started === 0 ? `${classes.orderDisabled} disabled` : null;
      break;
    case "two":
      if (data.is_started === 0) {
        orderStatus = `${classes.orderDisabled} disabled`;
      } else if (
        data.is_started === 1 &&
        data.product.category.category_id === 2 &&
        data.station_1 === null
      ) {
        orderStatus = `${classes.orderDisabled} disabled`;
      } else if (
        data.is_started === 1 &&
        data.product.category.category_id === 5 &&
        data.station_1 === null
      ) {
        orderStatus = `${classes.orderDisabled} disabled`;
      } else if (
        data.is_started === 1 &&
        data.product.category.category_id === 4 &&
        data.product.product_id !== 35 &&
        data.product.product_id !== 36 &&
        data.station_1 === null
      ) {
        orderStatus = `${classes.orderDisabled} disabled`;
      }
      break;
    case "three":
      if (data.is_started === 0) {
        orderStatus = `${classes.orderDisabled} disabled`;
      } else {
        if (data.station_1 !== null && data.station_2 === null) {
          orderStatus = `${classes.orderDisabled} disabled`;
        } else if (
          data.product.category.category_id !== 2 &&
          data.station_2 === null &&
          data.product.product_id !== 76 &&
          data.product.product_id !== 77 &&
          data.product.product_id !== 78 &&
          data.product.product_id !== 49 &&
          data.product.product_id !== 50 &&
          data.product.product_id !== 84 &&
          data.product.product_id !== 85 &&
          data.product.product_id !== 86 &&
          data.product.product_id !== 87 &&
          data.product.product_id !== 99 &&
          data.product.product_id !== 100

        ) {
          orderStatus = `${classes.orderDisabled} disabled`;
        }
      }
      break;
    case "four":
      if (data.is_started === 0) {
        orderStatus = `${classes.orderDisabled} disabled`;
      } else if (
        data.station_3 === null &&
        data.product.product_id !== 41 &&
        data.product.product_id !== 40 &&
        data.product.product_id !== 39
      ) {
        orderStatus = `${classes.orderDisabled} disabled`;
      }
      break;
    default:
      orderStatus = null;
  }
  const cardColor = orderStatus
    ? null
    : !data.is_active
    ? { background: "white" }
    : { background: "#DFFFD0" };

  const pizzaContent =
    data.product.options.length > 0 ? (
      <>
        <Grid className={classes.sizeWrap}>
          <Typography variant="h4">{data.product.options[0].value}</Typography>
          <Typography variant="h3">
            {data.product.options[0].name === "Тонкое" ? "T" : null}
          </Typography>
        </Grid>
        <Grid style={sauceColor} className={classes.sauce} />
      </>
    ) : null;
  return (
    <Card
      style={cardColor}
      onClick={(e) => makeOrderActive(e)}
      className={classes.orderWrap}
    >
      {orderComment}
      <Grid className={orderStatus} />
      <Typography className={classes.orderTitle} variant="h6">
        {data.product.name.toUpperCase()}
      </Typography>
      {pizzaContent}
      <Typography variant="h5">
        Заказ {data.order && data.order.invoice_no} - {data.count}{" "}
      </Typography>
      <p>Комментарий: </p>
      <p className={data.product.comment ? classes.comment : null}>
        {data.product.comment
          ? data.product.comment.toUpperCase()
          : "Отсутствует"}
      </p>
      <p className={classes.createdAt}>{formatedDate}</p>
    </Card>
  );
};

export default withStyles(styles)(Order);
