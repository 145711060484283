import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  CHANGE_QUANTITY,
  BACK_TO_LIST,
  ADD_PIZZA_TO_CART,
  EDIT_PIZZA,
  SUBMIT_EDIT_PIZZA,
  SET_PHONE,
  SUBMIT_PAYMENT,
  TOGGLE_SUCCESS_PAYMENT,
  OPEN_PRODUCT_INFO,
  CLOSE_PRODUCT_INFO,
  MAKE_REORDER,
  ADD_PROMO_PIZZA,
  PEPPERONI_COUPON,
  RECOUNT_TOTAL_PRICE,
  BIRTHDAY_COUPON,
  RETURN_INITIAL_PRODUCTS,
  PERCENT_DISCOUNT_COUPON,
  GET_CATEGORIES,
  PRODUCT_COUPON,
  CHECK_COMBO_COMBINATION,
  CLOSE_MAKE_COMBO,
  ADD_COMBO_TO_CART,
  EDIT_COMBO,
  UPDATE_COMBO_IN_CART,
  FIRST_ORDER_COUPON,
  SET_INITIAL_PRODUCTS,
  SEND_DATA_TO_ORDER,
  FIRST_TIME_ADD,
  CHANGE_COMMENT,
  START_SERVICE_ORDER,
  CHANGE_PRICE_THROUGH_SERVICE
}                                                                                                                            from '../types';
import {returnChangedItem, createNewCount, sortByOption, updateComboKeys, createCartItem, returnPromoPizza, returnPromoItem} from '../helperFunctions';

const initialState = {
  combos: [],
  initialProducts: [],
  products: [],
  editProduct: {},
  editCombo: {},
  initialPrice: 0,
  //если промокод не должен удаляться после возвращения в корзину, необходим этот ключ
  submitCount: 0,
  comboWindowOpened: false,
  cartAddOpened: false,
  promoAdded: null,
  phone: '',
  order: {},
  reorder: false,
  successPay: false,
  cardPay: false,
  firstTimeAdded: false,
  productInfo: {
    opened: false,
  },
  promocode: '',
  totalPrice: 0,
}
let closedEditWindow = {
  cartAddOpened: false,
}

export default function (state = initialState, { successPay, type, payload, changeType, discount, code }) {
  switch (type) {
    case CHANGE_PRICE_THROUGH_SERVICE: {
      let targetId = state.products.findIndex(item => item.render_id === payload.id);
      let newItem = {
        ...state.products[targetId],
        price: payload.value
      }
      newItem.total = newItem.price * newItem.quantity;
      return {
        ...state,
        products: returnChangedItem(state.products, newItem, targetId)
      }
    }
    case START_SERVICE_ORDER: {
      return {
        ...state,
        order: {
          ...state.order,
          platform_id: payload
        }
      }
    }
    case CHANGE_COMMENT: {
      let targetId = state.products.findIndex((item) => item.render_id === payload.id);
      let newItem = {
        ...state.products[targetId],
        comment: payload.value
      }
      state.products = returnChangedItem(state.products, newItem, targetId)
      return {
        ...state
      }
    }
    case FIRST_TIME_ADD: {
      return {
        ...state,
        firstTimeAdded: true
      }
    }
    case SEND_DATA_TO_ORDER: {
      return {
        ...state,
        order: {
          ...state.order,
          ...payload
        },
        phone: payload.telephone
      }
    }
    case UPDATE_COMBO_IN_CART:
      let old_id = payload.render_id;
      sortByOption(payload.products);
      payload = updateComboKeys(payload.products, payload);
      let comboAlreadyInCartIdx = state.products.findIndex(item => item.render_id === payload.render_id);
      if (payload.render_id === old_id) {
        return {
          ...state,
          comboWindowOpened: false,
          editCombo: {},
        }
      }

      if (comboAlreadyInCartIdx >= 0) {
        let comboAlreadyInCart = state.products[comboAlreadyInCartIdx]
        let newItem = {
          ...comboAlreadyInCart,
          quantity: comboAlreadyInCart.quantity + payload.quantity
        }
        state.products = returnChangedItem(state.products, newItem, comboAlreadyInCartIdx)
        return {
          ...state,
          comboWindowOpened: false,
          editCombo: {},
          products: [
            ...state.products.filter(item => item.render_id !== old_id)
          ]
        }
      } else {
        let removeComboIdx = state.products.findIndex(item => item.render_id === old_id);
        return {
          ...state,
          comboWindowOpened: false,
          editCombo: {},
          products: returnChangedItem(state.products, payload, removeComboIdx)
        }
      }
    case ADD_COMBO_TO_CART:
      sortByOption(payload.products);
      payload = updateComboKeys(payload.products, payload);

      const comboInCart = state.products.find(combo => combo.render_id === payload.render_id)
      if (comboInCart) {
        const item = {
          ...payload,
          quantity: comboInCart.quantity + 1
        }
        const comboIdx = state.products.findIndex(combo => combo.render_id === payload.render_id);
        return {
          ...state,
          editCombo: {},
          comboWindowOpened: false,
          products: returnChangedItem(state.products, item, comboIdx),
        }
      }

      return {
        ...state,
        comboWindowOpened: false,
        editCombo: {},
        products: [
          ...state.products,
          payload
        ]
      }
    case EDIT_COMBO:
      return {
        ...state,
        comboWindowOpened: true,
        editCombo: payload
      }
    case CLOSE_MAKE_COMBO:
      return {
        ...state,
        comboWindowOpened: false
      }
    // case CHECK_COMBO_COMBINATION:
    //   //проверка на то, что в корзине есть продукты из комбо, и если да, создание комбо продукта вместо них
    //   let rightCombo = false;
    //   let similarProducts = [];
    //   //ищем в корзине элементы из комбо
    //   state.combos.forEach((combo) => {
    //     combo.products.forEach((product) => {
    //       let similarProduct = state.products.find((item) => {
    //         if (item.activeOption) {
    //           return item.product_id === product.product_id && item.options.pizza.product_option_id === product.product_option_id
    //         }
    //         return item.product_id === product.product_id
    //       });
    //       if (similarProduct) {
    //         similarProducts.push(similarProduct)
    //       } else return;
    //     })
    //     similarProducts.forEach((product) => {
    //       combo.products.forEach((item) => {
    //         if (product.product_id === item.product_id && similarProducts.length === combo.products.length) {
    //           rightCombo = combo;
    //         }
    //       })
    //     })
    //   })
    //   if (rightCombo) {
    //     let newProducts = state.products;
    //     rightCombo = createCartItem(rightCombo);
    //     //создание массива продуктов с измененным количеством, если создается комбо
    //     newProducts.forEach((item) => {
    //       similarProducts.forEach((product) => {
    //         if (item === product) {
    //           let idx = newProducts.findIndex(item => item === product);
    //           if (item.quantity > 1) {
    //             let chosenItem = newProducts[idx]
    //             let newItem = createNewCount(chosenItem, 'decrement')
    //             newProducts = returnChangedItem(newProducts, newItem, idx)
    //           } else {
    //             newProducts = [
    //               ...newProducts.slice(0, idx),
    //               ...newProducts.slice(idx + 1)
    //             ]
    //           }
    //         }
    //       })
    //     })

    //     //проверка на наличие в корзине комбо
    //     const comboIdx = newProducts.findIndex(item => item.product_id === rightCombo.product_id);
    //     let prevCombo = newProducts[comboIdx];
    //     if (prevCombo) {
    //       let newItem = createNewCount(prevCombo, 'increment')
    //       return {
    //         ...state,
    //         products: returnChangedItem(newProducts, newItem, comboIdx)
    //       }
    //     }
    //     return {
    //       ...state,
    //       products: [
    //         ...newProducts,
    //         rightCombo
    //       ]
    //     }
    //   } else {
    //     return {
    //       ...state
    //     }
    //   }
    case RETURN_INITIAL_PRODUCTS:
      if (payload === '%') {
        let initialProducts = state.products;
        initialProducts.forEach((product, index) => {
          if (product.combo || product.id === 109) {
            return;
          }
          let newItem = {
            ...product,
            price: product.priceBeforeCoupon,
            priceBeforeCoupon: 0,
          }
          newItem.total = newItem.price * newItem.quantity;
          initialProducts = [
            ...initialProducts.slice(0, index),
            newItem,
            ...initialProducts.slice(index + 1)
          ]
        })
        return {
          ...state,
          products: initialProducts,
          initialPrice: 0,
          promoAdded: false,
        }
      }

      if (payload === 'h') {
        let initialProducts = state.products.filter((item) => !item.type);
        if (state.order.shipping_method !== "delivery") {
          initialProducts.forEach((product, index) => {
            if (product.combo || product.id === 109) {
              return;
            }
            let newItem     = {
              ...product,
              price            : product.priceBeforeCoupon,
              priceBeforeCoupon: 0,
            }
            newItem.total   = newItem.price * newItem.quantity;
            initialProducts = [
              ...initialProducts.slice(0, index),
              newItem,
              ...initialProducts.slice(index + 1)
            ]
          })
        }

        if (initialProducts[0].priceBeforeCoupon) {
          initialProducts.forEach((product, index) => {
            if (product.combo || product.id === 109) {
              return;
            }
            let newItem     = {
              ...product,
              price            : product.priceBeforeCoupon,
              priceBeforeCoupon: 0,
            }
            newItem.total   = newItem.price * newItem.quantity;
            initialProducts = [
              ...initialProducts.slice(0, index),
              newItem,
              ...initialProducts.slice(index + 1)
            ]
          })
        }

        return {
          ...state,
          products: initialProducts,
          initialPrice: 0,
          promoAdded: false,
        }
      }

      if (payload === 'p') {
        return {
          ...state,
          promoAdded: false,
          products: state.products.filter((item) => !item.type)
        }
      }

      if (payload === '1' || payload === 'd') {
        const promoProducts = state.products.filter((item) => item.type);
        promoProducts.forEach((item) => {
          let itemInCartId = state.products.findIndex(product => product.render_id === item.old_render_id);
          if (itemInCartId >= 0) {
            state.products[itemInCartId].quantity += item.quantity;
            state.products[itemInCartId].total = state.products[itemInCartId].quantity * state.products[itemInCartId].price;
          } else {
            let newItem = {
              ...item,
              render_id: item.old_render_id,
              price: item.priceBeforeCoupon,
              priceBeforeCoupon: 0,
            }
            delete newItem.type;
            newItem.total = newItem.price * newItem.quantity;
            let replaceId = state.products.findIndex(product => product.render_id === item.render_id);
            state.products = returnChangedItem(state.products, newItem, replaceId);
          }
        });
        state.products = state.products.filter(item => !item.type);
        return {
          ...state,
          totalPrice: state.initialPrice,
          initialPrice: 0,
          promoAdded: false,
        }
      }

      return {
        ...state,
      }

    case SET_INITIAL_PRODUCTS:
      return {
        ...state,
        initialPrice: state.totalPrice,
        initialProducts: state.products,
      }
    case RECOUNT_TOTAL_PRICE: {
      if (payload && payload.type) {
        //здесь будет код на изменение продуктов, на которые действуют the1 и 555
      }
      if (discount) {
        let newProductsArr = state.products;
        newProductsArr.forEach((product, index) => {
          if (product.combo || product.priceBeforeCoupon || product.id === 109) {
            return product;
          }
          if (state.promocode === 'хочу' && !product.activeOption) {
            return product;
          }
          if (product.priceBeforeCoupon && !product.initial_options.length) {
            return product;
          }
          let newItem = {
            ...product,
            price: Math.round(product.price * (1 - (discount / 100))),
            priceBeforeCoupon: product.price
          };
          newItem.total = newItem.price * newItem.quantity;
          newProductsArr = returnChangedItem(newProductsArr, newItem, index)
        });
        return {
          ...state,
          products: newProductsArr,
          totalPrice: state.products.reduce((total, product) => {
            return total += product.total
          }, 0),
          initialPrice: state.products.reduce((total, product) => {
            if (product.priceBeforeCoupon) {
              return total += product.priceBeforeCoupon * product.quantity
            }
            return total += product.price * product.quantity;
          }, 0)
        }
      }
      return {
        ...state,
        totalPrice: state.products.reduce((total, product) => {
          return total += product.total
        }, 0),
        initialPrice: state.products.reduce((total, product) => {
          if (product.priceBeforeCoupon) {
            return total += product.priceBeforeCoupon * product.quantity
          }
          return total += product.total
        }, 0)
      }
    }
    case FIRST_ORDER_COUPON: {
      let newStateProducts = state.products;
      //если купон будет действовать только на одну позицию
      if (payload.products.length === 1) {
        let targetId = state.products.findIndex(item => item.render_id === payload.products[0].render_id);
        let targetProduct = state.products[targetId];
        targetProduct = {
          ...targetProduct,
          type: 'p',
          priceBeforeCoupon: targetProduct.price,
          price: payload.products[0].price,
          render_id: payload.products[0].id,
          old_render_id: targetProduct.render_id,
        }
        targetProduct.total = targetProduct.price * targetProduct.quantity;
        if (targetProduct.quantity <= 2) {
          newStateProducts = returnChangedItem(newStateProducts, targetProduct, targetId)
        } else {
          targetProduct.quantity = payload.products[0].quantity;
          targetProduct.total = targetProduct.price * targetProduct.quantity;
          state.products[targetId].quantity -= payload.products[0].quantity;
          state.products[targetId].total = state.products[targetId].quantity * state.products[targetId].price;
          newStateProducts = [
            ...newStateProducts.slice(0, targetId + 1),
            targetProduct,
            ...newStateProducts.slice(targetId + 1)
          ]
        }
      } else {
        //если купон будет действовать на две позиции
        const newProducts = payload.products;
        newProducts.forEach((item) => {
          let targetId = state.products.findIndex(product => product.render_id === item.render_id);
          let targetProduct = state.products[targetId];
          targetProduct = {
            ...targetProduct,
            render_id: item.id,
            old_render_id: targetProduct.render_id,
            type: 'p',
            priceBeforeCoupon: targetProduct.price,
            price: item.price,
            quantity: item.quantity
          }
          targetProduct.total = targetProduct.price * targetProduct.quantity;
          if (state.products[targetId].quantity >= 2) {
            state.products[targetId].quantity -= item.quantity;
            state.products[targetId].total = state.products[targetId].quantity * state.products[targetId].price;
            newStateProducts = [
              ...newStateProducts.slice(0, targetId + 1),
              targetProduct,
              ...newStateProducts.slice(targetId + 1)
            ]
          } else {
            newStateProducts = returnChangedItem(newStateProducts, targetProduct, targetId);
          }
        })
      }
      return {
        ...state,
        promoAdded: true,
        products: newStateProducts,
      }
    }
    case PERCENT_DISCOUNT_COUPON:
      let newProducts = state.products;
      newProducts.forEach((item) => {
        if (code === 'хочу' && !item.activeOption) {
          return;
        }
        if (item.combo || item.id === 109) {
          return;
        }
        let idx = newProducts.findIndex(product => product.render_id === item.render_id);
        let newItem = {
          ...item,
          priceBeforeCoupon: item.price,
          price: Math.round(item.price * ((100 - payload.discount) / 100))
        }
        newItem.total = newItem.quantity * newItem.price;
        newProducts = returnChangedItem(newProducts, newItem, idx)
      })
      return {
        ...state,
        promocode: code,
        products: newProducts,
        promoAdded: true,
      }
    case PRODUCT_COUPON: {
      if (payload.options.length) {
        let newPizza = returnPromoPizza(payload)
        return {
          ...state,
          promoAdded: true,
          products  : [
            ...state.products,
            newPizza
          ]
        }
      } else {
        let newItem = returnPromoItem(payload)
        return {
          ...state,
          promoAdded: true,
          products  : [
            ...state.products,
            newItem
          ]
        }
      }
    }
    case BIRTHDAY_COUPON: {
      const newPizza = returnPromoPizza(payload);
      let newProducts = state.products;

      if (state.order.shipping_method !== "delivery") {

        newProducts.forEach((item) => {
          if (code === 'хочу' && !item.activeOption) {
            return;
          }
          if (item.combo || item.id === 109) {
            return;
          }
          let idx       = newProducts.findIndex(product => product.render_id === item.render_id);
          let newItem   = {
            ...item,
            priceBeforeCoupon: item.price,
            price            : Math.round(parseInt(item.price) * 0.9)
          }
          newItem.total = newItem.quantity * newItem.price;
          newProducts   = returnChangedItem(newProducts, newItem, idx)
        })
      }

      return {
        ...state,
        promoAdded: true,
        products: [
          ...newProducts,
          newPizza
        ]
      }
    }
    case PEPPERONI_COUPON: {
      let targetId = state.products.findIndex(item => item.render_id === payload.products[0].render_id);
      if (payload.products.length === 1) {
        let promoPepperoni = payload.products[0];
        let newItem = {
          ...state.products[targetId],
          render_id: promoPepperoni.id,
          old_render_id: promoPepperoni.render_id,
          price: promoPepperoni.price,
          priceBeforeCoupon: state.products[targetId].price,
          type: 'd',
          total: promoPepperoni.total
        }
        state.products = returnChangedItem(state.products, newItem, targetId);
      } else {
        let promoPepperoni = payload.products.find(item => item.quantity !== 1);
        let newItem = {
          ...state.products[targetId],
          render_id: promoPepperoni.id,
          old_render_id: promoPepperoni.render_id,
          price: promoPepperoni.price,
          priceBeforeCoupon: state.products[targetId].price,
          type: 'd',
          quantity: promoPepperoni.quantity,
          total: promoPepperoni.total
        }
        state.products[targetId].quantity -= newItem.quantity;
        state.products[targetId].total = state.products[targetId].quantity * state.products[targetId].price;
        state.products = [
          ...state.products.slice(0, targetId + 1),
          newItem,
          ...state.products.slice(targetId + 1)
        ]
      }
      return {
        ...state,
        promoAdded: true
      }
    }
    case ADD_PROMO_PIZZA: {
      return {
        ...state,
        promoAdded: true,
        products: [
          ...state.products,
          payload,
        ],
      }
    }
    case MAKE_REORDER:
      return {
        ...state,
        reorder: true,
      }
    case SET_PHONE:
      return {
        ...state,
        phone: payload
      };
    case CLOSE_PRODUCT_INFO:
      return {
        ...state,
        productInfo: {
          ...state.productInfo,
          opened: false
        }
      };
    case OPEN_PRODUCT_INFO:
      return {
        ...state,
        productInfo: {
          ...payload,
          opened: true
        }
      };
    case SUBMIT_PAYMENT:
      return {
        ...state,
        submitCount: state.submitCount + 1,
        order: {
          ...state.order,
          customer_id: '1',
          customer_group: '1',
          telephone: state.phone,
          total: state.totalPrice,
        },
      }
    case TOGGLE_SUCCESS_PAYMENT:
      return {
        ...state,
        successPay,
      }
    case EDIT_PIZZA:
      return {
        ...state,
        cartAddOpened: true,
        editProduct: {
          ...payload,
          // oldPrice: payload.price
        },
      }
    case SUBMIT_EDIT_PIZZA:
      let product = payload.product;
      let removeItemIndex = state.products.findIndex(item => item.id === payload.previousId);
      product.render_id = Number(String(product.id) + product.options.pizza.product_option_id + (product.options.sauce ? product.options.sauce.product_option_id : 0));
      let newItem = {
        ...product,
      };
      if (discount) {
        newItem.priceBeforeCoupon = product.price;
        newItem.price = Math.round(product.price * (1 - (discount / 100)));
      }
      newItem.total = newItem.price * newItem.quantity;
      return {
        ...state,
        products: returnChangedItem(state.products, newItem, removeItemIndex),
        editProduct: {},
        cartAddOpened: false
      }
    case ADD_PIZZA_TO_CART:
      const { sauce, pizza } = payload.options;
      payload.product_id = payload.id;
      payload.render_id = Number(String(payload.id) + pizza.product_option_id + (sauce ? sauce.product_option_id : 0));
      payload.quantity = 1;
      payload.total = payload.price * payload.quantity;
      const pizzaInCart = state.products.find(item => item.render_id === payload.render_id);
      if (pizzaInCart) {
        const newItem = {
          ...payload,
          quantity: pizzaInCart.quantity + 1,
        }
        const itemIndex = state.products.findIndex(item => item.render_id === payload.render_id);
        return {
          ...state,
          cartAddOpened: false,
          products: returnChangedItem(state.products, newItem, itemIndex),
          editProduct: {},
        }
      }
      return {
        ...state,
        cartAddOpened: false,
        products: [
          ...state.products,
          payload
        ],
        editProduct: {},
      }
    case BACK_TO_LIST:
      return {
        ...state,
        ...closedEditWindow,
        editProduct: {},
      }
    case CHANGE_QUANTITY:
      const decision = changeType;
      const targetId = payload;
      const targetIndex = state.products.findIndex(item => item.render_id === targetId);
      const target = state.products[targetIndex];
      let changedItem = {};
      if (decision === 'increase') {
        changedItem = ({
          ...target,
          quantity: target.quantity + 1,
          total: target.price * (target.quantity + 1)
        })
      } else {
        if (target.quantity === 1) {
          return {
            ...state
          }
        }
        changedItem = createNewCount(target, 'decrement')
      }
      return {
        ...state,
        products: returnChangedItem(state.products, changedItem, targetIndex)
      }
    case ADD_TO_CART:
      let chosenItem = createCartItem(payload);
      const idx = state.products.findIndex(item => item.render_id === chosenItem.render_id);
      const itemInCart = state.products[idx];
      if (itemInCart) {
        const newItem = {
          ...itemInCart,
          quantity: itemInCart.quantity + 1,
          total: itemInCart.price * (itemInCart.quantity + 1)
        }
        return {
          ...state,
          ...closedEditWindow,
          products: returnChangedItem(state.products, newItem, idx)
        }
      }
      if (payload.combo) {
        return {
          ...state,
          comboWindowOpened: true,
          editCombo: chosenItem
        }
      }
      if (payload.options.length > 0) {
        return {
          ...state,
          cartAddOpened: true,
          editProduct: chosenItem,
        }
      }

      return {
        ...state,
        products: [
          ...state.products,
          chosenItem
        ],
      };
    case GET_CATEGORIES:
      let combos = payload.find(item => item.name === 'Комбо').products;
      return {
        ...state,
        combos
      }
    case REMOVE_FROM_CART:
      let deleteItemId = payload;
      return {
        ...state,
        products: [
          ...state.products.filter(item => item.render_id !== deleteItemId)
        ]
      };
    default:
      return state
  };
}